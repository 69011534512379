import { inject } from '@angular/core';
import { ActivatedRoute, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';

import { firstValueFrom, filter } from 'rxjs';

import { AuthService } from './auth.service';
import { UserService } from './user.service';

export const authGuard: CanActivateFn = async (_, state: RouterStateSnapshot) => {
  const authService = inject(AuthService);
  const router = inject(Router);
  const userService = inject(UserService);
  const route = inject(ActivatedRoute);

  // make sure the auth service is ready (ie. we know the user is logged in)
  await firstValueFrom(authService.isReady$.pipe(filter(isReady => isReady)));

  // this extracts error messages from the url and redirects to the sign-in page
  // this is needed because the supabase auth client doesn't handle these errors
  if (state.url.includes('error_description')) {
    const hashUrl = new URL(state.url.replace('#', '?'), window.location.origin);
    const errorDescription = hashUrl.searchParams.get('error_description');
    if (errorDescription) {
      return router.createUrlTree(['/sign-in'], {
        queryParams: { error_message: errorDescription },
      });
    }
  }

  if (!userService.user) {
    const { data } = await authService.getSession();
    const user = authService.getAuthUser(data.session?.user);
    userService.setUser(user);
    if (!user) {
      return router.createUrlTree(['/sign-in'], { queryParams: { returnUrl: state.url } });
    }
  }

  if (!userService.isUserRegistered && route.routeConfig?.path !== 'sign-up/registration') {
    return router.createUrlTree(['/sign-up/registration'], {
      queryParams: { returnUrl: state.url },
    });
  }

  return true;
};

export const signedOutGuard: CanActivateFn = async () => {
  const userService = inject(UserService);
  const router = inject(Router);

  if (userService.user) {
    return router.createUrlTree(['/']);
  }

  return true;
};

export const adminGuard: CanActivateFn = async () => {
  const authService = inject(AuthService);
  const userService = inject(UserService);
  const router = inject(Router);

  // make sure the auth service is ready (ie. we know the user's role)
  await firstValueFrom(authService.isReady$.pipe(filter(isReady => isReady)));

  if (userService.user?.user_type !== 'admin' && userService.user?.user_type !== 'editor') {
    return router.createUrlTree(['/']);
  }

  return true;
};
